
const ESellerState =
{
  Confirmed: 1,
  Canceled: 2,
  Suspended: 3,
  Waiting: 4,
  Rejected: 5,
  NotSended: 6
}


export default ESellerState; 

