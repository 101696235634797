
const EBigCalenderType = 
{
  AvailableTimeSelection: 0,
  ClassTimeSelection: 1,
  MiniAvailableTimeShow: 2,
  CustomerAvailableBookSelection_Individual: 3,
  CustomerAvailableBookSelection_Class: 4,
  SellerBookedScheduler: 5,
  CustomerBookedScheduler: 6,
}


export default EBigCalenderType; 
