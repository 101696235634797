import DefaultTheme from "../Themes/DefaultTheme";

const GlobalStyles = (_Theme) => ({
  Theme: _Theme,
  margin5: {
    margin: "5px",
  },
  margin15: {
    margin: "15px",
  },
  DisableTextTransform: {
    textTransform: "none !important",
  },
  margint15: {
    marginTop: "15px",
  },
  paper: {
    padding: _Theme.spacing(2),
    textAlign: "center",
    color: _Theme.palette.text.secondary,
  },
  dialogCustomizedWidth: {
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundColor: "rgb(0 0 0 / 0)",
    boxShadow: "none",
    overflowY: "hidden",
  },
  cardGroupResponsive: {
    justifyContent: "center",
    textAlign: "center",
    background: DefaultTheme.palette.secondary.contrastText,
    [_Theme.breakpoints.down("xs")]: {
      marginLeft: "-30px",
      marginRight: "-30px",
      marginTop: "65px",
    },
  },
  formResponsive: {
    [_Theme.breakpoints.down("xs")]: {
      marginBlockStart: "-40px",
    },
  },
  signIn: {
    border: "1px solid #f44336",
    borderRadius: "4px",
    color: DefaultTheme.palette.primary.main,
    [_Theme.breakpoints.down("xs")]: {
      fontSize: "7px",
    },
  },
  buttonFont: {
    fontFamily: "Montserrat",
    [_Theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [_Theme.breakpoints.up("lg")]: {
      fontSize: "14px",
    },
  },
  buttonText: {
    textTransform: "none",
  },
  displayState: {
    [_Theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  mainGridStyle: {
    overflow: "hidden",
    background: DefaultTheme.palette.default.mainLight,
    [_Theme.breakpoints.only("xs")]: {
      marginBlockEnd: "unset",
      marginBlockStart: "unset",
    },
  },
  displayState: {
    [_Theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  formGrid: {
    textAlign: "center",
    marginBlockStart: "50px",
  },
  responsiveImg: {
    [_Theme.breakpoints.only("sm")]: {
      marginLeft: "20px",
    },
    [_Theme.breakpoints.only("xs")]: {
      marginLeft: "30px",
      marginRight: "30px",
      order: "2",
      marginBlockEnd: "30px",
    },
  },
  dividerGrid: {
    display: "grid",
    justifyContent: "center",
    margin: "50px",
    [_Theme.breakpoints.only("xs")]: {
      display: "none",
    },
    [_Theme.breakpoints.only("sm")]: {
      margin: "unset",
      marginLeft: "70px",
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    marginBlockEnd: "10px",
  },
  formTabs: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "bold",
  },
  communicationForm: {
    display: "block",
    [_Theme.breakpoints.only("xs")]: {
      margin: "25px",
    },
  },
  labelStyle: {
    fontFamily: "Montserrat",
  },
  responsiveGrid: {
    [_Theme.breakpoints.only("xs")]: {
      order: "1",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  formTitle: {
    fontFamily: "Montserrat",
    fontSize: "26px",
    marginBlockEnd: "10px",
    fontWeight: "bold",
    color: DefaultTheme.palette.default.mainDark,
  },
  iconStyle: {
    fill: DefaultTheme.palette.success.light,
  },
  buttonStyle: {
    color: _Theme.palette.getContrastText(DefaultTheme.palette.primary.main),
    backgroundColor: DefaultTheme.palette.primary.main,
  },
  formElement: {
    marginBlockStart: "25px",
    display: "block",
  },
  formElementSecond: {
    display: "block",
    marginTop: "10px",
  },
  autoCompleteStyle: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    borderColor: DefaultTheme.palette.primary.main,
    fontFamily: "Montserrat",
  },
  input: {
    color: DefaultTheme.palette.secondary.light,
    fontFamily: "Montserrat",
    borderColor: "red",
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },

    "& .MuiOutlinedInput-input": {
      color: "green",
    },

    "& .MuiInputLabel-outlined": {
      color: "green",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "red",
    },
    ".MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
  },
  preregisterButton: {
    fontFamily: "Montserrat",
    textDecoration: "none",
    textTransform: "none",
    fontSize: "14px",
    [_Theme.breakpoints.only("xs")]: {
      fontSize: "12px",
    },
    [_Theme.breakpoints.only("sm")]: {
      fontSize: "13px",
    },
    [_Theme.breakpoints.only("md")]: {
      fontSize: "14px",
    },
  },
  subTitle3: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    color: "gray",
    textDecoration: "none",
    fontWeight: "bold",
    marginBlockStart: "15px",
  },
  educationForm: {
    display: "block",
    [_Theme.breakpoints.only("xs")]: {
      margin: "25px",
    },
  },
  labelStyle: {
    fontFamily: "Montserrat",
  },
  labelStyle2: {
    fontFamily: "Montserrat",
  },
  iconStyle2: {
    fill: DefaultTheme.palette.success.light,
  },
  formElement2: {
    marginBlockStart: "25px",
    display: "grid",
  },
  formElementForgotPassword: {
    marginBlockStart: "25px",
  },
  formElementCheck: {
    display: "grid",
  },
  textFieldStyle: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    borderColor: DefaultTheme.palette.primary.main,
    fontFamily: "Montserrat",
  },
  textField2: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    borderColor: DefaultTheme.palette.primary.main,
    "& .MuiFormLabel-root.Mui-focused": {
      color: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },

    "& .MuiOutlinedInput-input": {
      color: "green",
    },

    "& .MuiInputLabel-outlined": {
      color: "green",
    },
    ".MuiInput-underline:after": {
      borderBottom: "2px solid red",
    },
  },
  acceptContract: {
    fontFamily: "Montserrat",
    fontSize: "13px",
    textAlign: "left",
    [_Theme.breakpoints.only("xs")]: {
      fontSize: "11px",
    },
  },
  formElement: {
    marginBlockStart: "20px",
    display: "grid",
  },
  preregisterButton: {
    fontFamily: "Montserrat",
    textDecoration: "none",
    textTransform: "none",
    fontSize: "14px",
    [_Theme.breakpoints.only("xs")]: {
      fontSize: "12px",
    },
    [_Theme.breakpoints.only("sm")]: {
      fontSize: "13px",
    },
    [_Theme.breakpoints.only("md")]: {
      fontSize: "14px",
    },
  },
  alignImg: {
    [_Theme.breakpoints.only("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "auto",
    },
  },
  subTitle3: {
    fontFamily: "Montserrat",
    fontSize: "11px",
    color: DefaultTheme.palette.info.darkAlternative,
    textDecoration: "none",
    marginBlockStart: "15px",
  },
  contractButtons: {
    textTransform: "none",
  },
  contractButtonText: {
    fontFamily: "Montserrat",
    fontSize: "11px",
    color: DefaultTheme.palette.info.contrastAlternative,
    textDecoration: "underline",
  },
  subTitle4: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    color: "gray",
    textDecoration: "none",
    marginBlockStart: "10px",
  },
  forgotPassword: {
    fontFamily: "Montserrat",
    fontSize: "12px",
  },
  registerButton: {
    fontFamily: "Montserrat",
    fontSize: "15px",
    [_Theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  checkStyle1: {
    margin: "auto",
  },
  checkStyle2: {
    margin: "auto",
  },
  signupButton: {
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  enterButton: {
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  poweredByText: {
    fontFamily: "Montserrat",
    color: DefaultTheme.palette.default.secondAlternative,
    fontWeight: "bold",
    [_Theme.breakpoints.only("xs")]: {
      fontSize: "11px",
    },
  },
  poweredByTextPart2: {
    color: _Theme.palette.primary.main,
  },
  marqueeText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: _Theme.palette.primary.main,
  },
  checkBoxStyle: {
    display: "flex",
    justifyContent: "space-around",
  },
  studentInfoGrid: {
    marginTop: "25px",
    background: DefaultTheme.palette.secondary.contrastText,
    [_Theme.breakpoints.only("xs")]: {
      order: "3",
    },
  },
  studentInfoGrid2: {
    marginTop: "25px",
    marginBottom: "25px",
    background: DefaultTheme.palette.secondary.contrastText,
    [_Theme.breakpoints.only("xs")]: {
      order: "3",
    },
  },
  mainGridPadding: {
    padding: "30px",
    justifyContent: "center",
    width: "100%",
    [_Theme.breakpoints.only("sm")]: {
      paddingLeft: "25px",
      paddingRight: "25px",
    },
    [_Theme.breakpoints.only("xs")]: {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  formAreaStyle: {
    justifyContent: "center",
    textAlign: "center",
    background: DefaultTheme.palette.secondary.contrastText,
  },
  imageGridStyle: {
    border: "unset",
    height: "100%",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [_Theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainGridPaddingInForgotPassword: {
    padding: "30px",
    justifyContent: "center",
    width: "100%",
    [_Theme.breakpoints.only("sm")]: {
      paddingLeft: "25px",
      paddingRight: "25px",
    },
    [_Theme.breakpoints.only("xs")]: {
      paddingLeft: "unset",
      paddingRight: "unset",
    },
  },
  formAreaStyle2: {
    justifyContent: "center",
    textAlign: "center",
    background: DefaultTheme.palette.secondary.contrastText,
    padding: "10px",
  },
  appStyle: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "100vh",
  },
  alignItemsCenter: {
    alignItems: "center !important",
  },
  flexRow: {
    flexDirection: "row !important",
  },
  fabScrollTopStyle: {
    position: "fixed",
    right: "15px",
    bottom: "32px",
    fontSize: "3rem",
    zIndex: 999,
    cursor: "pointer",
    width: "40px",
    height: "40px",
    [_Theme.breakpoints.only("lg")]: {
      left: "93%",
    },
    [_Theme.breakpoints.only("md")]: {
      left: "90%",
    },
    [_Theme.breakpoints.only("sm")]: {
      left: "85%",
    },
    [_Theme.breakpoints.only("xs")]: {
      left: "80%",
    },
  },
  // =========================
  // ContractPageStyle.js
  // =========================
  menuItem: {
    "&:focus": {
      backgroundColor: _Theme.palette.primary.main,
      "& $primary, & $icon": {
        color: _Theme.palette.common.white,
      },
    },
    paddingLeft: "unset",
    paddingTop: "unset",
    paddingBottom: "unset",
  },
  primary: {
    fontFamily: "Montserrat",
    [_Theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
    [_Theme.breakpoints.up("sm")]: {
      fontSize: "12px",
    },
    [_Theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
    [_Theme.breakpoints.up("lg")]: {
      fontSize: "14px",
    },
  },
  icon: {},
  contractTextPaper: {
    [_Theme.breakpoints.down("xs")]: {
      padding: "3px",
    },
    [_Theme.breakpoints.up("sm")]: {
      padding: "10px",
    },
    [_Theme.breakpoints.up("md")]: {
      padding: "15px",
    },
    [_Theme.breakpoints.up("lg")]: {
      padding: "20px",
    },
  },
  emptyGrid: {
    [_Theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mainGrid: {
    overflowX: "hidden",
    overflowY: "hidden",
    overflow: "hidden",
    [_Theme.breakpoints.down("xs")]: {
      padding: "10px",
      marginBlockStart: "30px",
    },
    [_Theme.breakpoints.up("sm")]: {
      padding: "20px",
      marginBlockStart: "60px",
      margin: "unset",
    },
    [_Theme.breakpoints.up("md")]: {
      padding: "30px",
      marginBlockStart: "30px",
      margin: "unset",
    },
    [_Theme.breakpoints.up("lg")]: {
      padding: "35px",
      margin: "unset",
    },
  },
  selectButton: {
    textTransform: "none",
    fontFamily: "Montserrat",
  },
  listItemTex: {
    fontFamily: "Montserrat",
  },
});

export default GlobalStyles;
