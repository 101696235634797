
const ESharedSessionType = 
{
  None: { Code: "None", ID : 0},
  Console: { Code: "Console", ID: 1 },
  Screen: { Code: "Screen", ID: 2 },
}


export default ESharedSessionType; 
