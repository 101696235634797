import React, { Component ,useState, useEffect } from "react";
import Loading from "../TagComponents/Utilities/Loading";

import TApp from "./TApp";



export default class TLoader extends Component {
  constructor(props) {
    super();
  }


  render() {
    return (
      <React.Suspense fallback={<Loading />}>
        <TApp />
      </React.Suspense>
    );
  }
}
