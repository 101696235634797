import EBigCalenderCellState from "./EBigCalenderCellState";
import ESellerState from "./ESellerState";
import EBigCalenderType from "./EBigCalenderType";
import ELessonType from "./ELessonType";
import EFileType from "./EFileType";
import EFileExtentionType from "./EFileExtentionType";
import ENotificationType from "./ENotificationType";
import ENotificationChannels from "./ENotificationChannels";
import EBillType from "./EBillType";
import ECustomerBookedState from "./ECustomerBookedState";
import ETicketState from "./ETicketState";
import ESharedSessionType from "./ESharedSessionType";
import EBannerType from "./EBannerType";

window.Enums = function () {};

window.Enums.ToDictionary = function (_EnumList) {
  var __Enums = {};

  _EnumList.map(function (_Item, _Index) {
    __Enums[_Item.Code] = _Item;
  });
  return __Enums;
};

window.Enums.EBigCalenderCellState = EBigCalenderCellState;
window.Enums.ESellerState = ESellerState;
window.Enums.EBigCalenderType = EBigCalenderType;
window.Enums.ELessonType = ELessonType;
window.Enums.EFileType = EFileType;
window.Enums.EFileExtentionType = EFileExtentionType;
window.Enums.ENotificationType = ENotificationType;
window.Enums.ENotificationChannels = ENotificationChannels;
window.Enums.EBillType = EBillType;
window.Enums.ECustomerBookedState = ECustomerBookedState;
window.Enums.ETicketState = ETicketState;
window.Enums.ESharedSessionType = ESharedSessionType;
window.Enums.EBannerType = EBannerType;
