var App = function () {
}

App.Aside = null;
App.Header = null;
App.ContainerLayout = null;
App.HotSpotMessage = null;
App.MessageBox = null;
App.User = null;
App.SessionID = "";
App.GlobalLoading = null;
App.App = null;
App.ProfileEdit = null;
App.ChangePassword = null;
App.DefaultPasswordSet=null;
App.AddressInformation = null;
App.AddressInformationDetail = null;
App.PaymentCardInformation = null;
App.PaymentCardInformationDetail = null;
App.LoginPageModal = null;
App.ProfileEdit = null;
App.ChangeProfileImage = null;
App.ServerDate = null;
App.TeacherInformations = null;
App.TeacherLessonModal = null;
App.PreTeacherLessonModal = null;
App.ConfigBackupModal=null;
App.BatchJobEditModal = null;
App.CreditPackageDefinationEditModal=null;
App.LanguageEditModal = null;
App.TeacherAvailableTimeModal = null;
App.GlobalParams = null;
App.SellerForCustomer = null;
App.LessonDetailModal = null;
App.SellerLessonDetailModal = null;
App.SellerAvailabilityCalendarModal = null;
App.CustomerComments = null;
App.Checked = false;
App.SelectAndBuyLessonModal = null;
App.LessonSubLessonDetailsModal = null;
App.PreRequestLessonDetailModal = null;
App.PreRequestResponseModal = null;
App.BigCalender_CellModal_SellerBookedScheduler = null;
App.BigCalender_CellModal_CustomerBookedScheduler = null;
App.UnloginHeader = null;
App.SubscriberLessonCreditTypeModal = null;
App.SSSForTutorsModal = null;
App.SSSForStudentsModal = null;
App.DataTableModal = null;
App.SearchFilter_ClassLevel = null;
App.TeacherConfirmationModal = null;
App.SendSellerConfirmationModal = null;
App.OpenNewTicketModal = null;
App.SellerRegister = null;
App.SellerRegisterSuccess = null;
App.CustomerRegisterSuccess = null;
App.Register = null;
App.CustomerOrderModal = null;
App.SellerProfileShareModal = null;
App.SSSModal = null;
App.CountDownSpinner=null;
App.TagsModal = null;
App.CustomerUnratedReservations = null;
App.CustomerReservationRatingModal = null;
App.SellerBigCalendar = null;
App.SubscriptionCancelModal = null;
App.CancelSubscriptionReasonModal = null;
App.CancelSubscriptionReasonEditModal = null;
App.CouponTrendyolEditModal = null;
App.LessonEditModal = null;
App.CustomerMissingSubject = null;
App.SellerEvaluationModal = null;
App.PreRegistrationSuccessTelephoneModal = null;
window.App = App;


