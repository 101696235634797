
const EBannerType = 
{
  SellerRegisterMainImg: { Code: "SellerRegisterMainImg", ID : 1},
  MainPageUpImg: { Code: "MainPageUpImg", ID : 2},
  
}


export default EBannerType; 
