
const EFileType =
{
  Global: 0,
  Proof: 1,
  IdImage: 2,
  Student: 3
}


export default EFileType; 
