
const ENotificationType =
{
  None: 0,
  CustomerBookedLesson: 1,
  UpcomingLessonsNotification: 2,
  CancelLessonsNotification: 3,
  SupportNewMessageNotification: 4,
  SellerProfileUpdateNotification: 5,
}


export default ENotificationType; 
