
const EFileExtentionType =
{
  NONE : 0,
  JPEG : 1,
  JPG : 2,
  PNG : 3,
  PDF : 4,
  DOC : 5,
  DOCX : 6
}


export default EFileExtentionType; 
