import "./WebGraph/Initializers";
import "./WebGraph/App";
//import './WebGraph/GenericWebController/Managers/LanguageManager/Language';
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Router } from "react-router-dom";
//import App from './App';
import TLoader from "./WebGraph/TagComponents/TLoader";
import { Ripple } from "react-preloaders";
//import "assets/scss/material-kit-react.scss?v=1.9.0";

import "./scss/App.scss";


import process from "process";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-3VG9XM9",
};

TagManager.initialize(tagManagerArgs);


ReactDOM.render(
  <Router history={window.History}>
    <React.Fragment>
      <div style={{ fontFamily: "Montserrat" }}>
        <TLoader />
      </div>
      {/* <Ripple color={"#ff5757"} /> */}
    </React.Fragment>
  </Router>,
  document.getElementById("root")
);
