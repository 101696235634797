
const ECustomerBookedState = 
{
  Unknown : 0,
  Valid : 1,
  CanceledByCustomer : 2,
  CanceledBySeller : 3,
  CanceledByAdmin : 4
}


export default ECustomerBookedState; 

