import React from "react";
import logo from "../../../assets/img/edunet/edunette.webp";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from "classnames";
import {withStyles} from "@material-ui/core/styles";
import GlobalStyles from "../../../ScriptStyles/GlobalStyles";

var Loading = function (props) {
  const {classes} = props;
  return (
    <div className={classNames(classes.appStyle, classes.alignItemsCenter, classes.flexRow)}>
      <Grid container justifyContent={"center"} style={{overflow: "hidden"}}>
        <Grid item xs={12} style={{ display: "contents" }}>
          <img
            alt={'edunette'}
            src={logo}
            loading="lazy"

          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          
        </Grid>
      </Grid>
    </div>  );
};

export default withStyles(GlobalStyles) (Loading);
