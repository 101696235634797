
const ETicketState =
{
  Open : 1,
  Pending : 2,
  Resolved : 3,
  Closed : 4,
  Canceled: 5,
  WaitingReplay: 6,
}


export default ETicketState; 

