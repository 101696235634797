import { createBrowserHistory } from "history";
import GenericWebGraph from "../../../WebGraph/GenericWebController/GenericWebGraph";
import { WebGraph } from "../../../WebGraph/GenericCoreGraph/WebGraph/WebGraph";
import { escapeLeadingUnderscores } from "typescript";

window.History = createBrowserHistory();

window.LastPage = "";

window.History.listen((_Location, _Action) => {

  var __Url = _Location.pathname;
  if (__Url.startsWith("/"))
  {
    var __Url = __Url.substring(1);
  }

  
  if (__Url == window.LastPage)
  {
    WebGraph.OnUrlChanged();
  }

  window.LastPage = __Url;

  if (_Action == "POP")
  {
    setTimeout(function ()
    {
      WebGraph.ForceUpdateAllForPop(true);

      if (window.App.ContainerLayout && window.App.ContainerLayout != null)
      {
        window.App.ContainerLayout.HandleRefreshMenu();
      }
    });
  }
  else
  {

  }

});

