import DefaultTheme from "./DefaultTheme";
import TestTheme from "./TestTheme";

var Themes = function () {
}

Themes.DefaultTheme = DefaultTheme;
Themes.TestTheme = TestTheme;


window.Themes = Themes;
