
const EBigCalenderCellState = 
{
  NotSelected: 0,
  SelectedFromSeller: 1,
  SelectableIndividualCellFromCustomer: 2,
  BookedByYou: 3,
  BookedByOther: 4,
  BookedGroupByYou: 5,
  GroupCell: 6,
  SelectableGroupCellFromCustomer: 7,
  GroupCellFull: 8,
  SelectedFromCustomer: 9,
  GroupCell_ForSellerBookedView: 10,
  IndividualCell_ForBookedSellerView: 11,
  GroupCell_ForCustomerBookedView: 12,
  IndividualCell_ForCustomerSellerView: 13,
}


export default EBigCalenderCellState; 
