import React, { Component } from "react";
import { HashRouter, Route, Router, Switch, Redirect } from "react-router-dom";
import { WebGraph } from "../../WebGraph/GenericCoreGraph/WebGraph/WebGraph";
import GenericWebGraph from "../../WebGraph/GenericWebController/GenericWebGraph";
import Loading from "../TagComponents/Utilities/Loading";
import {
  ObjectTypes,
  DebugAlert,
} from "../../WebGraph/GenericCoreGraph/ClassFramework/Class";

import { ThemeProvider } from "@material-ui/styles";
//import "scss/App.scss";
import "Themes/Themes.js";
const TMainLoading = React.lazy(() => import("./TMainLoading"));

const UnloginedLayout = React.lazy(() =>
  import("./Pages/UnloginedPages/Containers/UnloginedLayout")
);
const AdminLayout = React.lazy(() =>
  import("./Pages/AdminPages/Containers/AdminLayout")
);
const CustomerLayout = React.lazy(() =>
  import("./Pages/CustomerPages/Containers/CustomerLayout")
);
const SellerLayout = React.lazy(() =>
  import("./Pages/SellerPages/Containers/SellerLayout")
);
const DeveloperLayout = React.lazy(() =>
  import("./Pages/DeveloperPages/Containers/DeveloperLayout")
);

const TMessageBox = React.lazy(() => import("./Listeners/TMessageBox"));
const TOpenLoginModal = React.lazy(() => import("./Listeners/TOpenLoginModal"));
const THotSpotMessage = React.lazy(() => import("./Listeners/THotSpotMessage"));
const TGlobalLoading = React.lazy(() => import("./Utilities/TGlobalLoading"));
const TDynamicLoader = React.lazy(() => import("./TDynamicLoader"));

const TProfileEdit = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TProfileEdit")
);
const TChangePassword = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TChangePassword")
);
const TDefaultPasswordSet = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TDefaultPasswordSet")
);

const TAddressInformation = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TAddressInformation")
);
const TAddressInformationDetail = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TAddressInformationDetail")
);
const TPaymentCardInformation = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TPaymentCardInformation")
);
const TPaymentCardInformationDetail = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TPaymentCardInformationDetail")
);

const TLoginPageModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TLoginPageModal")
);

const TChangeProfileImage = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TChangeProfileImage")
);
const TCustomerMissingSubject = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TCustomerMissingSubject")
);
const TLessonDetailModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TLessonDetailModal")
);
const TSelectAndBuyLessonModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TSelectAndBuyLessonModal")
);
const TSellerAvailabilityCalendarModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TSellerAvailabilityCalendarModal")
);
const TCustomerComments = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TCustomerComments")
);
const TSellerProfileShareModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TSellerProfileShareModal")
);
const TLessonSubLessonDetailsModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TLessonSubLessonDetailsModal")
);
const TSellerLessonDetailsModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TSellerLessonDetailModal")
);

const TCustomerOrderModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TCustomerOrderModal")
);
const TCustomerReservationRatingModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TCustomerReservationRatingModal")
);
const TSSSModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TSSSModal")
);
const TCountDownSpinner = React.lazy(() =>
  import("./Utilities/TCountDownSpinner")
);
const TTagsModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TTagsModal")
);
const TCookieConsent = React.lazy(() =>
  import("./Pages/GlobalPages/TCookieConsent")
);
const TSubscriptionDetailModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TSubscriptionDetailModal")
);

const TSubscriptionCancelModal = React.lazy(() =>
  import("./Pages/CustomerPages/Modals/TSubscriptionCancelModal")
);

///////////////// Admin ////////////////

const TOpenNewTicketModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TOpenNewTicketModal")
);

///////////////// Admin ////////////////

const TSubscriberLessonCreditTypeModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TSubscriberLessonCreditTypeModal")
);
const TDataTableModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TDataTableModal")
);

///////////////// Admin //////////////////

const TTeacherConfirmationModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TTeacherConfirmationModal")
);
const TConfigBackupModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TConfigBackupModal")
);

const TBatchJobEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TBatchJobEditModal")
);
const TCreditPackageDefinationEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TCreditPackageDefinationEditModal")
);

const TLanguageEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TLanguageEditModal")
);
const TMarqueeEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TMarqueeEditModal")
);
const TBannerEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TBannerEditModal")
);
const TCouponTrendyolEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TCouponTrendyolEditModal")
);
const TLessonEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TLessonEditModal")
);

const TCampaignEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TCampaignEditModal")
);

const TCancelSubscriptionReasonModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TCancelSubscriptionReasonModal")
);
const TCancelSubscriptionReasonEditModal = React.lazy(() =>
  import("./Pages/AdminPages/Modals/TCancelSubscriptionReasonEditModal")
);

///////////////// Seller ////////////////

const TTeacherInformationsModal = React.lazy(() =>
  import("./Pages/SellerPages/Modals/TTeacherInformationsModal")
);

const TTeacherAvailableTimeModal = React.lazy(() =>
  import("./Pages/SellerPages/Modals/TTeacherAvailableTimeModal")
);

const TTeacherLessonModal = React.lazy(() =>
  import("./Pages/SellerPages/Modals/TTeacherLessonModal")
);
const TPreTeacherLessonModal = React.lazy(() =>
  import("./Pages/SellerPages/Modals/TPreTeacherLessonModal")
);
const TBigCalender_CellModal_SellerBookedScheduler = React.lazy(() =>
  import(
    "./Components/BigCalender/Modals/TBigCalender_CellModal_SellerBookedScheduler"
  )
);
const TBigCalender_CellModal_CustomerBookedScheduler = React.lazy(() =>
  import(
    "./Components/BigCalender/Modals/TBigCalender_CellModal_CustomerBookedScheduler"
  )
);

const TSendSellerConfirmationModal = React.lazy(() =>
  import("./Pages/SellerPages/Modals/TSendSellerConfirmationModal")
);

const TVideoPage = React.lazy(() => import("./Pages/GlobalPages/TVideoPage"));

const TSellerEvaluationModal = React.lazy(() =>
  import("./Pages/SellerPages/Modals/TSellerEvaluationModal")
);

/////////////////////////////////////////

/////////////////UnLogined /////////////

const TUnloginHeader = React.lazy(() =>
  import("./Pages/UnloginedPages/Containers/UnloginHeader")
);

const TPreRegistrationSuccessTelephoneModal = React.lazy(() =>
  import("./Pages/GlobalPages/Modals/TPreRegistrationSuccessTelephoneModal")
);

export default class TApp extends Component {
  constructor(props) {
    super();

    this.GetModal = this.GetModal.bind(this);
    this.GetLayout = this.GetLayout.bind(this);
    this.GetTheme = this.GetTheme.bind(this);

    window.App.App = this;
    this.state = {
      ...this.state,
    };
    WebGraph.Init();
    GenericWebGraph.Init();
  }

  GetModal() {
    if (window.App.User != null) {
      if (window.App.User.Roles[0].Code == "Admin") {
        return (
          <div>
            <TProfileEdit />
            <TChangePassword />
            <TDefaultPasswordSet />
            <TAddressInformation />
            <TAddressInformationDetail />
            <TTeacherConfirmationModal />
            <TConfigBackupModal />
            <TBatchJobEditModal />
            <TCreditPackageDefinationEditModal />
            <TLanguageEditModal />
            <TMarqueeEditModal />
            <TBannerEditModal />
            <TCouponTrendyolEditModal />
            <TLessonEditModal />
            <TCampaignEditModal />

            <TChangeProfileImage />
            <TLoginPageModal />
            <TCancelSubscriptionReasonModal />
            <TCancelSubscriptionReasonEditModal />
            <TSubscriptionCancelModal />
            <TTeacherInformationsModal />
            <TSendSellerConfirmationModal />
          </div>
        );
      } else if (window.App.User.Roles[0].Code == "Seller") {
        return (
          <div>
            <TProfileEdit />
            <TChangePassword />
            <TAddressInformation />
            <TAddressInformationDetail />
            <TTeacherInformationsModal />
            <TTeacherAvailableTimeModal />
            <TTeacherLessonModal />
            <TPreTeacherLessonModal />
            <TSendSellerConfirmationModal />
            <TBigCalender_CellModal_SellerBookedScheduler />
            <TOpenNewTicketModal />
            <TLessonDetailModal />
            <TLessonSubLessonDetailsModal />
            <TCustomerComments />
            <TDataTableModal />
            <TSellerProfileShareModal />
            <TSSSModal />
            <TTagsModal />
            <TSellerAvailabilityCalendarModal />
            <TLoginPageModal />
            <TSellerEvaluationModal/>
          </div>
        );
      } else if (window.App.User.Roles[0].Code === "Customer") {
        return (
          <div>
            <TProfileEdit />
            <TChangePassword />
            <TAddressInformation />
            <TAddressInformationDetail />
            <TBigCalender_CellModal_CustomerBookedScheduler />
            <TSellerLessonDetailsModal />
            <TOpenNewTicketModal />
            <TCustomerOrderModal />
            <TCustomerReservationRatingModal />
            <TPaymentCardInformation />
            <TPaymentCardInformationDetail />
            <TChangeProfileImage />
            <TCustomerMissingSubject />
            <TSelectAndBuyLessonModal />
            <TLessonDetailModal />
            <TLessonSubLessonDetailsModal />
            <TCustomerComments />
            <TDataTableModal />
            <TSellerProfileShareModal />
            <TSSSModal />
            <TTagsModal />
            <TSellerAvailabilityCalendarModal />
            <TLoginPageModal />
            <TSubscriptionDetailModal />
            <TSubscriptionCancelModal />
          </div>
        );
      } else if (window.App.User.Roles[0].Code === "Developer") {
        return (
          <div>
            <TProfileEdit />
            <TChangePassword />
            <TAddressInformation />
            <TAddressInformationDetail />
            <TChangeProfileImage />
            <TLessonDetailModal />
            <TLessonSubLessonDetailsModal />
            <TCustomerComments />
            <TDataTableModal />
            <TSellerProfileShareModal />
            <TSSSModal />
            <TTagsModal />
            <TSellerAvailabilityCalendarModal />
            <TLoginPageModal />
          </div>
        );
      } else if (window.App.User.Roles[0].Code === "Developer") {
        return <div></div>;
      }
    } else {
      return (
        <div>
          <TSelectAndBuyLessonModal />
          <TSellerAvailabilityCalendarModal />
          <TLoginPageModal />
          <TOpenLoginModal />
          <TLessonDetailModal />
          <TLessonSubLessonDetailsModal />
          <TCustomerComments />
          <TSellerProfileShareModal />
          <TSSSModal />
          <TTagsModal />
          <TPreRegistrationSuccessTelephoneModal/>
        </div>
      );
    }
    return null;
  }

  GetLayout(_Props) {
    if (!window.App.Checked) {
      return <TMainLoading {..._Props} />;
    } else {
      var __ProfileName = _Props.location.pathname.substring(
        1,
        _Props.location.pathname.length
      );
      var __IsTutorUrl = __ProfileName.startsWith(
        window.Pages.Names.Egitmen + "/"
      );

      if (window.App.User == null) {
        return <UnloginedLayout {..._Props} isTutorUrl={__IsTutorUrl} />;
      } else {
        if (__ProfileName == "videopage") {
          return <TVideoPage />;
        } else {
          if (window.App.User.Roles[0].Code == "Admin") {
            return <AdminLayout {..._Props} isTutorUrl={__IsTutorUrl} />;
          } else if (window.App.User.Roles[0].Code == "Seller") {
            return <SellerLayout {..._Props} isTutorUrl={__IsTutorUrl} />;
          } else if (window.App.User.Roles[0].Code == "Developer") {
            return <DeveloperLayout {..._Props} isTutorUrl={__IsTutorUrl} />;
          } else {
            return <CustomerLayout {..._Props} isTutorUrl={__IsTutorUrl} />;
          }
        }
      }
    }
  }

  GetTheme() {
    if (window.App.User != null) {
      if (window.App.User.Roles[0].Code == "Admin") {
        return window.Themes.DefaultTheme;
      } else if (window.App.User.Roles[0].Code == "Seller") {
        return window.Themes.DefaultTheme;
      } else if (window.App.User.Roles[0].Code === "Customer") {
        return window.Themes.DefaultTheme;
      } else if (window.App.User.Roles[0].Code === "Developer") {
        return window.Themes.DefaultTheme;
      }
    }
    return window.Themes.DefaultTheme;
  }

  render() {
    return (
      <ThemeProvider theme={this.GetTheme()}>
        <TDynamicLoader />
        <THotSpotMessage />
        <TMessageBox />
        <TGlobalLoading />

        {this.GetModal()}
        <Switch>
          <Route path="/" name="Ana Sayfa" render={this.GetLayout} />
        </Switch>
        <TCookieConsent />
      </ThemeProvider>
    );
  }
}
