import React, { Component } from "react";
import {
  DebugAlert,
  Class,
  Interface,
  Abstract,
  ObjectTypes,
  JSTypeOperator,
} from "../../../GenericCoreGraph/ClassFramework/Class";
import { WebGraph } from "../../../GenericCoreGraph/WebGraph/WebGraph";
import cBaseObject from "../../../GenericCoreGraph/BaseObject/cBaseObject";
import Pages from "../../../TagComponents/Pages";
import $ from "jquery";
import queryString from "query-string";

var cBannerManager = Class(
  cBaseObject,
  {
    ObjectType: ObjectTypes.Get("cBannerManager"),
    constructor: function () {
      cBannerManager.BaseObject.constructor.call(this);

      this.FirstLoadSetBanner();
    },
    HandleSetActiveBanner: function (_Banner) {
      let __ObjMap = {};

      _Banner.forEach((element) => {
        var __MakeKey = element.BannerType;
        if (!__ObjMap[__MakeKey]) {
          __ObjMap[__MakeKey] = [];
        }

        __ObjMap[__MakeKey].push({
          Title: element.Title,
          Content: element.Content,
          BannerClickLink: element.BannerClickLink,
          Image: element.Image,
        });
      });
      this.ActiveBanner = __ObjMap;
    },
    FirstLoadSetBanner(_BannerCode) {
      var __Url = window.GetUrlParams();
      var __Params = queryString.parse(__Url);
      var __ForcedBanner = "";

      var __Banner = null;
      var __This = this;
      $.ajax({
        async: false,
        type: "POST",
        url: "/api/WebApi/WebApi",
        dataType: "json",
        data: JSON.stringify({
          CID: 17,
          Data: {},
        }),
        success: function (_Data) {
          if (_Data[0] != null && _Data[0].Data != null) {
            __Banner = _Data[0].Data.ResultList;
          }
        },
        error: function () {},
        complete: function () {},
      });

      this.HandleSetActiveBanner(__Banner);
    },
    Destroy: function () {
      cBannerManager.BaseObject.Destroy.call(this);
    },
  },
  {}
);

export default cBannerManager;
